<template>
    <section class="pt-3">
        <div class="row mx-0 pl-4 align-items-center">
            <i class="icon-bank f-20 text-general" />
            <div class="col-3">
                <el-select v-model="idCuenta" filterable class="w-100" size="small" @change="listaTransferenciasCuentasBancarias">
                    <el-option
                    v-for="item in cuentasBancarias"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div v-if="$can('botones_flujo_dinero_cuentas_gestionar')" class="col-auto px-0">
                <el-tooltip placement="bottom" content="Editar cuentas bancarias" effect="light">
                    <router-link :to="{name: 'admin.tesoreria.flujo-dinero.cuentas-bancarias.editar'}">
                        <div class="btn-action border">
                            <i class="icon-pencil-outline text-general f-17" />
                        </div>
                    </router-link>
                </el-tooltip>
            </div>
        </div>
        <div v-if="idCuenta === null" class="row text-general-red justify-center bg-gris mt-2 br-10 mx-3 py-2">
            Por favor seleccione una cuenta bancaria
        </div>
        <div v-else class="row mx-0 pt-2">
            <div class="col-12 px-0">
                <tabla-general
                :data="dataTable"
                alto="calc(100vh - 346px)"
                class-header="text-general f-16"
                >
                    <template slot="cabecera-izquierda">
                        <div class="col-auto text-general f-18 f-600">
                            Pagos por transferencia
                        </div>
                        <div class="col d-middle text-general">
                            <div class="col-auto ml-auto">
                                <i class="icon-attention-alt text-general-red f-20" />
                                {{ agregarSeparadoresNumero(enEspera) }} Pago en espera
                            </div>
                            <div class="col-auto">
                                <i class="icon-alert-triangle text-warning f-20" />
                                {{ agregarSeparadoresNumero(sinVerificar) }} pagos sin verificar
                            </div>
                        </div>
                    </template>
                    <template slot="adicionales-izquierda">
                        <el-table-column label="Estado" width="180" class-name="cr-pointer">
                            <template slot-scope="{ row }">
                                <p class="text-general" @click="detallePago(row)"> <i :class="`icon-${iconos(row.transferencia_estado)} ml-2`" /> {{ textosEstados(row.transferencia_estado) }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="Fecha Pago" width="250" sort-by="created_at" sortable class-name="cr-pointer">
                            <template slot-scope="{ row }">
                                <p @click="detallePago(row)">{{ formatearFecha(row.created_at, 'D MMM Y - HH:mmA') }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="Leechero" width="180" class="text-general text-right" sort-by="leechero.nombre" sortable class-name="cr-pointer">
                            <template slot-scope="{ row }">
                                <p @click="detallePago(row)">{{ row.leechero.nombre }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="Pago" width="100" class="text-general text-right" sort-by="valor" sortable class-name="cr-pointer">
                            <template slot-scope="{ row }">
                                <p @click="detallePago(row)">{{ separadorNumero(row.valor) }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="Encargado">
                            <template slot-scope="{ row }">
                                <div v-if="row.transferencia_estado == 1 || row.transferencia_estado == 4" class="row mx-0">
                                    <div v-if="$can('boton_flujo_dinero_cuentas_verificar_pago')" class="bg-general3 cr-pointer text-white br-20 px-3 f-14" @click="verificar(row)">
                                        Verificar
                                    </div>
                                    <div v-if="row.transferencia_estado == 1 && $can('boton_flujo_dinero_cuentas_en_espera_pago')" class="bg-white cr-pointer border text-general br-20 px-3 f-14 mx-2" @click="dejarEspera(row)">
                                        Dejar en espera
                                    </div>
                                    <div v-if="row.transferencia_estado == 4 && $can('boton_flujo_dinero_cuentas_descartar_pago')" class="bg-white cr-pointer border text-general br-20 px-3 f-14 mx-2" @click="descartarPago(row)">
                                        Descartar Pago
                                    </div>
                                </div>
                                <p v-else class="text-general f-16">
                                    {{ row.encargado.nombre }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column label="Fecha verificación" width="250" sort-by="updated_at" sortable>
                            <template slot-scope="{ row }">
                                <p>{{ formatearFecha(row.updated_at, 'D MMM Y - HH:mmA') }}</p>
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
        <!-- Partials -->
        <modal ref="modalVerificarTransferencia" titulo="Verificar transferencia" no-aceptar adicional="Verificar" @adicional="editarTransferencia">
            <p class="text-general f-15 my-3 text-center">¿Verificar la transferencia del pago?</p>
        </modal>
        <modal ref="modalDejarEspera" titulo="Dejar en espera" @guardar="editarTransferencia">
            <ValidationObserver ref="validacion">
                <div class="row mx-0 mt-3 justify-center">
                    <div class="col-12 text-center text-general">
                        ¿Deseas dejar este pago en espera?
                    </div>
                    <div class="col-12 text-center text-general">
                        Se puede verificar más adelante mientras se resuelve el inconveniente en el que puede estar relacionado.
                    </div>
                    <div class="col-8 mt-4">
                        <ValidationProvider v-slot="{errors}" vid="justificacion1" rules="required|max:150" name="justificación">
                            <p class="pl-3 text-general">Justificación</p>
                            <el-input v-model="justificacion" class="w-100" type="textarea" :rows="5" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </modal>
        <modal-detalle-pago ref="modalDetallePago" @esperar="dejarEspera" @descartarP="descartarPago" @verificar="verificar" />
        <modal ref="modalDescartarPago" titulo="Descartar pago" no-aceptar adicional="Descartar" @adicional="editarTransferencia">
            <ValidationObserver ref="validacion">
                <div class="row mx-0 mt-3 justify-center">
                    <div class="col-12 text-center text-general">
                        ¿Deseas descartar este pago?
                    </div>
                    <div class="col-8 mt-4">
                        <ValidationProvider v-slot="{errors}" vid="justificacion2" rules="required|max:150" name="justificación">
                            <p class="pl-3 text-general">Justificación</p>
                            <el-input v-model="justificacion" class="w-100" type="textarea" :rows="5" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </modal>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import CuentasBancarias from "~/services/flujoDinero/cuentas_bancarias";
export default {
    components: {
        modalDetallePago: () => import('./partials/modalDetallePago')
    },
    data(){
        return{
            cuentasBancarias: [],
            idCuenta: null,
            justificacion: '',
            dataTable: [],
            model: {
                id: null,
                transferencia_comentario: null,
                transferencia_estado: null,
            }
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
        enEspera(){
            return this.dataTable.filter(el => el.transferencia_estado == 4).length
        },
        sinVerificar(){
            return this.dataTable.filter(el => el.transferencia_estado == 1).length
        }
    },
    watch: {
        id_cedis(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.idCuenta = null
            this.dataTable = []
            this.selectCuentasBancarias()
        },
        id_moneda(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.listaTransferenciasCuentasBancarias()
        }
    },
    mounted(){
        this.montar()
    },
    methods: {
        async montar(){
            try {
                this.selectCuentasBancarias()
            } catch (error){
                this.error_catch(error)
            }finally{
            }
        },
        async listaTransferenciasCuentasBancarias(){
            try {
                const params = {
                    id_moneda: this.id_moneda,
                    id_cuenta: this.idCuenta
                }
                const { data } = await CuentasBancarias.listaTransferenciasCuentasBancarias(params)
                this.dataTable = data.data.cuentasBancarias
            } catch (error){
                this.error_catch(error)
            }
        },
        async selectCuentasBancarias(){
            try {
                const { data } = await CuentasBancarias.selectCuentasBancarias()

                this.cuentasBancarias = data.data.cuentasBancarias
            } catch (error){
                this.error_catch(error)
            }
        },
        async editarTransferencia(){
            try {
                if (this.model.transferencia_estado != 2){
                    const valid = await this.$refs.validacion.validate()
                    if(!valid){
                        this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                        return false
                    }
                    this.model.transferencia_comentario = this.justificacion
                }
                const { data } = await CuentasBancarias.editarTransferencia(this.model)
                this.notificacion('Mensaje', data.message, 'success')
                switch (this.model.transferencia_estado){
                case 2:
                    this.$refs.modalVerificarTransferencia.toggle()
                    break;
                case 4:
                    this.$refs.modalDejarEspera.toggle()
                    break;
                case 3:
                    this.$refs.modalDescartarPago.toggle()
                    break;
                default:
                    break;
                }
                let index = this.dataTable.findIndex(el => el.id == data.pago.id)
                this.$set(this.dataTable, index, data.pago)
                this.limpiar()
            } catch (error){
                this.error_catch(error)
            }
        },
        textosEstados(state){
            switch (state){
            case 4:
                return 'En espera';
            case 1:
                return 'Sin verificar';
            case 2:
                return 'Verificado';
            case 3:
                return 'Descartado';
            }
        },
        iconos(state){
            switch (state){
            case 4:
                return 'attention-alt text-general-red';
            case 1:
                return 'alert-triangle text-warning';
            case 2:
                return 'ok-circled-outline text-success';
            case 3:
                return 'cancel-outline text-general';
            }
        },
        verificar({ id }){
            this.model.id = id
            this.model.transferencia_estado = 2
            this.$refs.validacion.reset()
            this.$refs.modalVerificarTransferencia.toggle();
        },
        dejarEspera({ id }){
            this.model.id = id
            this.model.transferencia_estado = 4
            this.$refs.validacion.reset()
            this.$refs.modalDejarEspera.toggle()
        },
        descartarPago({ id }){
            this.model.id = id
            this.model.transferencia_estado = 3
            this.$refs.validacion.reset()
            this.$refs.modalDescartarPago.toggle();
        },
        detallePago(transferencia){
            this.$refs.modalDetallePago.toggle(transferencia,2)
        },
        limpiar(){
            this.model = {
                id: null,
                transferencia_comentario: null,
                transferencia_estado: null,
            }
            this.$refs.validacion.reset()
        }
    }
}
</script>
<style lang="scss" scoped>
.btn-cuenta{
    width: 32px;
    height: 32px;
    background-image: linear-gradient(to bottom, #FFFFFF, #F3F5F7);
}
</style>
